// @flow

import * as React from "react";
import axios from 'axios';

import {Card, Form, Grid, Icon, Page, Table, Button} from "tabler-react";
import spinner from "../images/spinner.gif"
import {Modal} from "react-bootstrap";
import {Document, Page as PDFPage} from "react-pdf";
import Swal from 'sweetalert2'
import {isValidEmailAddress} from "../Utils"
import withReactContent from 'sweetalert2-react-content'
import {toast} from "react-toastify";

const swalInstance = withReactContent(Swal)

class Home extends React.Component {

    constructor() {
        super();
        this.state = {
            invoicesLeftThisMonth: 0,
            recentlyProcessedInvoices: [],
            totalEntries: 0,
            currentPage: 1,
            viewQrInvoiceModalVisibility: false,
            selectedInvoiceToViewTitle: '',
            selectedInvoiceToViewUrl: '',
            selectedInvoiceToViewId: '',
            sendQrInvoiceModalVisibility: false,
            selectedInvoiceToSendTitle: '',
            selectedInvoiceToSendUrl: '',
            selectedInvoiceToSendId: '',
            nowDownloadingQrInvoiceId: '',
            showDeleteDialog: false,
            remainingQrInvoicesForThisMonth: 0,
            planMaxQrInvoicesForMonth: 0,
            planName: '',
            userSettings: {},
            showOnboardingModal: (localStorage.getItem("onboardingModalDismissed") === null),
            messageRecipient: '',
            messageRecipientIsInvalid: false,
            messageRecipientErrorMessage: '',
            messageSubject: '',
            messageSubjectIsInvalid: false,
            messageSubjectErrorMessage: '',
            messageContent: '',
            messageContentIsInvalid: false,
            messageContentErrorMessage: '',
            sendInvoiceButtonLoading: false
        };

        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.viewInvoice = this.viewInvoice.bind(this);
        this.deleteInvoice = this.deleteInvoice.bind(this);
        this.dismissOnboardingModal = this.dismissOnboardingModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    componentDidMount() {
        let url = new URL(window.location.href);
        let showMessage = url.searchParams.get('showMessage');
        if (showMessage === "1") {
            swalInstance.fire({
                icon: "success",
                title: "Plan Activated Successfully",
                text: "You can now access and use your new plan's features",
                confirmButtonColor: "green",
            });
        }
        else if (showMessage === "2") {
            swalInstance.fire({
                icon: "success",
                title: "Plan Reactivated Successfully",
                text: "You can now access and use your plan's features again",
                confirmButtonColor: "green",
            });
        }

        Promise.all([
            axios.get(window.SERVER_URL + '/get_settings'),
            axios.get(window.SERVER_URL + '/get_qr_invoices?index=1')
        ])
            .then(([res1, res2]) => Promise.all([res1.data, res2.data]))
            .then(([data1, data2]) => {
                    if (data1.result.is_active === false) {
                        this.props.history.push("/subscriptions");
                    }
                    this.setState({
                        userSettings: data1.result,
                        remainingQrInvoicesForThisMonth: data1.result.remaining_qr_invoices_this_month,
                        planMaxQrInvoicesForMonth: data1.result.plan_max_qr_invoices_per_month,
                        planName: data1.result.plan_name,
                        recentlyProcessedInvoices: data2.result.qr_invoices,
                        totalEntries: data2.result.total_entries,
                        totalPages: data2.result.total_pages,
                    });
                }
            );
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.setState({currentPage: this.state.currentPage - 1},
                function () {
                    let _this = this;
                    axios.get(window.SERVER_URL + '/get_qr_invoices?index=' + this.state.currentPage).then(function (response) {
                        _this.setState({
                            recentlyProcessedInvoices: response.data.result.qr_invoices,
                            totalEntries: response.data.result.total_entries,
                            totalPages: response.data.result.total_pages,
                        })
                    });
                }
            );
        }
    }

    nextPage() {
        this.setState({currentPage: this.state.currentPage + 1},
            function () {
                let _this = this;
                axios.get(window.SERVER_URL + '/get_qr_invoices?index=' + this.state.currentPage).then(function (response) {
                    _this.setState({
                        recentlyProcessedInvoices: response.data.result.qr_invoices,
                        totalEntries: response.data.result.total_entries,
                        totalPages: response.data.result.total_pages,
                    })
                });
            }
        );
    }

    downloadInvoice(invoiceId, invoiceTitle) {
        this.setState({nowDownloadingQrInvoiceId: invoiceId});
        axios.get(window.SERVER_URL + '/download_user_qr_pdf/' + invoiceId + '.pdf').then((res) => {
            const link = document.createElement('a');
            link.href = res.data;
            link.setAttribute('target', '_blank');
            link.setAttribute('download', invoiceTitle + " (QRify).pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.setState({nowDownloadingQrInvoiceId: ''});
        }).catch((err) => console.log(err));
    }

    deleteInvoice(invoiceId) {
        let _this = this;
        axios.post(window.SERVER_URL + '/delete_user_qr_invoice/' + invoiceId + '.pdf').then((res) => {
            axios.get(window.SERVER_URL + '/get_qr_invoices?index=' + this.state.currentPage).then(function (response) {
                _this.setState({
                    recentlyProcessedInvoices: response.data.result.qr_invoices,
                    totalEntries: response.data.result.total_entries,
                    totalPages: response.data.result.total_pages,
                    showDeleteDialog: false
                });
            });
        }).catch((err) => console.log(err));
    }

    viewInvoice(invoiceId, invoiceTitle) {
        this.setState({
                selectedInvoiceToViewTitle: invoiceTitle,
                selectedInvoiceToViewUrl: window.SERVER_URL + '/mirror_user_qr_pdf/' + invoiceId + '.pdf',
                viewQrInvoiceModalVisibility: true
            }
        );
    }

    handleChange(e) {
        let value = e.target.value;
        if ((e.target.name === "messageSubject" || e.target.name === "messageRecipient") && value.length > 200) {
            return;
        }

        this.setState({[e.target.name]: value});
    }

    sendInvoice(invoiceId, invoiceTitle) {
        this.setState({
                selectedInvoiceToSendId: invoiceId,
                selectedInvoiceToSendTitle: invoiceTitle,
                selectedInvoiceToSendUrl: window.SERVER_URL + '/mirror_user_qr_pdf/' + invoiceId + '.pdf',
                sendQrInvoiceModalVisibility: true,
                messageSubject: invoiceTitle.substring(0, invoiceTitle.indexOf('(') - 1) + " from " + this.state.userSettings.company_name,
                messageContent: "Hello,\n" +
                    "\n" +
                    "We appreciate your business. Please find your invoice details here. Feel free to contact us if you have any questions.\n" +
                    "\n" +
                    "Have a great day!\n" + this.state.userSettings.company_name,
            }
        );
    }

    dismissOnboardingModal() {
        localStorage.setItem("onboardingModalDismissed", "true");
        this.setState({showOnboardingModal: false})
    }

    deleteIconClicked(qrInvoice) {
        this.setState({selectedInvoiceToViewId: qrInvoice.id, selectedInvoiceToViewTitle: qrInvoice.invoice_title});
        let _this = this;
        swalInstance.fire({
            icon: "warning",
            title: "Are you sure you'd like to delete " + this.state.selectedInvoiceToViewTitle + "?",
            text: "This generated QR invoice will be deleted",
            cancelButtonText: "Cancel",
            confirmButtonText: "Yes, Delete",
            confirmButtonColor: "red",
            showCancelButton: true
        }).then(function (result) {
            if (result.isConfirmed) {
                _this.deleteInvoice(_this.state.selectedInvoiceToViewId);
            }
        });
    }

    sendEmail() {
        let _this = this;
        this.setState({
            sendInvoiceButtonLoading: true,
            messageRecipientIsInvalid: false,
            messageSubjectIsInvalid: false
        });
        if (this.state.messageRecipient.length < 1 || isValidEmailAddress(this.state.messageRecipient) === false) {
            this.setState({messageRecipientIsInvalid: true, sendInvoiceButtonLoading: false});
            return;
        }

        if (this.state.messageSubject.length < 1) {
            this.setState({messageSubjectIsInvalid: true, sendInvoiceButtonLoading: false});
            return;
        }

        axios.post(window.SERVER_URL + '/send_user_qr_invoice_as_email',
            {
                qr_invoice_id: this.state.selectedInvoiceToSendId,
                message_recipient: this.state.messageRecipient,
                message_subject: this.state.messageSubject,
                message_content: this.state.messageContent,
            }
        ).then(function (response) {
            if (response.status === 200) {
                toast.success("Invoice sent successfully");
                _this.setState({
                    sendInvoiceButtonLoading: false,
                    messageRecipient: '',
                    messageSubject: '',
                    messageContent: '',
                    sendQrInvoiceModalVisibility: false
                });
            }
        }).catch(error => {
            console.log(error);
            toast.error("An error has occurred");
        });
    }

    render() {
        let barWidth = 100 - ((parseInt(this.state.remainingQrInvoicesForThisMonth) / parseInt(this.state.planMaxQrInvoicesForMonth)) * 100);
        return (
            <Page.Content title="Dashboard">
                <Modal dialogClassName="onboarding-modal" show={this.state.sendQrInvoiceModalVisibility}
                       onHide={() => this.setState({sendQrInvoiceModalVisibility: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send QR Invoice: {this.state.selectedInvoiceToSendTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid.Row>
                            <Grid.Col width={6} sm={6} lg={6}>
                                <Form.Group>
                                    <b>To:</b><br/>
                                    <Form.Input
                                        name="messageRecipient"
                                        maxLength="200"
                                        invalid={this.state.messageRecipientIsInvalid}
                                        placeholder="Enter message recipient here"
                                        onChange={this.handleChange}
                                        value={this.state.messageRecipient}
                                    />
                                    <span className="invalid-feedback">Invalid recipient address</span>
                                </Form.Group>
                                <Form.Group>
                                    <b>Subject:</b><br/>
                                    <Form.Input
                                        name="messageSubject"
                                        maxLength="200"
                                        title="Subject"
                                        invalid={this.state.messageSubjectIsInvalid}
                                        value={this.state.messageSubject}
                                        placeholder="Enter message subject here"
                                        onChange={this.handleChange}
                                    />
                                    <span className="invalid-feedback">The message subject cannot be empty</span>
                                </Form.Group>
                                <Form.Group>
                                    <b>Message:</b> (optional)<br/>
                                    <Form.Textarea
                                        maxLength={2000}
                                        name="messageContent"
                                        rows="10"
                                        type="text"
                                        invalid={this.state.messageContentIsInvalid}
                                        placeholder="Enter message body here (optional)"
                                        onChange={this.handleChange}
                                        value={this.state.messageContent}/>
                                    <span className="invalid-feedback">{this.state.messageContentErrorMessage}</span>
                                    *The Invoice will be sent as an attachment
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col width={6} sm={6} lg={6}>
                                <Document file={this.state.selectedInvoiceToSendUrl}>
                                    <PDFPage pageNumber={1}/>
                                </Document>
                            </Grid.Col>
                        </Grid.Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button loading={this.state.sendInvoiceButtonLoading} color="success" onClick={this.sendEmail}>Send
                            Invoice&nbsp;<Icon name="send"></Icon>&nbsp;&nbsp;</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.viewQrInvoiceModalVisibility}
                       onHide={() => this.setState({viewQrInvoiceModalVisibility: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Viewing Generated QR Invoice: {this.state.selectedInvoiceToViewTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{textAlign: "center"}}>
                        <div>
                            <Document file={this.state.selectedInvoiceToViewUrl}>
                                <PDFPage pageNumber={1}/>
                            </Document>
                            <br/>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal dialogClassName="onboarding-modal" show={this.state.showOnboardingModal}
                       onHide={this.dismissOnboardingModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Welcome to QRify!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{textAlign: "center"}}>
                        <div>
                            <span style={{fontSize: "18px"}}><b>Watch this video in order to learn how to add a QR Code onto your QuickBooks invoices:</b></span><br/><br/>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/3LAG-fdWzbc"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                    </Modal.Body>
                </Modal>
                <Grid.Row cards={true}>
                    <Grid.Col width={6} sm={6} lg={6}>
                        <Card>
                            <div class="card-body text-center">
                                <div class="h3">Total QR Invoices Created<br/><span style={{fontSize: "16px"}}>In All Times</span>
                                </div>
                                <div class="display-4 font-weight-bold mb-4">{this.state.totalEntries}</div>
                            </div>
                            <span style={{display: (barWidth >= 50) ? "block" : "none", fontSize: "16px"}}>&nbsp;</span>
                            <div style={{visibility: "hidden"}} class="progress progress-sm">
                                <div class="progress-bar"></div>
                            </div>
                        </Card>
                    </Grid.Col>
                    <Grid.Col width={6} sm={6} lg={6}>
                        <Card>
                            <div class="card-body text-center">
                                <div class="h3">QR Invoices Left This Month<br/><span
                                    style={{fontSize: "16px", color: "darkgreen"}}>{this.state.planName}</span>&nbsp;<a style={{fontSize: "13px", color: "blue"}}
                                    href="#" onClick={() => this.props.history.push("/subscriptions")}>(Change Plan)</a></div>
                                <div
                                    class="display-4 font-weight-bold mb-4">{this.state.remainingQrInvoicesForThisMonth}/{this.state.planMaxQrInvoicesForMonth}</div>
                                <span style={{
                                    display: barWidth >= 50 ? "block" : "none",
                                    fontSize: "16px",
                                    color: "blue"
                                }}><span
                                    style={{color: "black"}}>Limits reset on {this.state.userSettings.first_day_next_month}.</span> <a
                                    href="#" onClick={() => this.props.history.push("/subscriptions")}>Get More QR Invoices Now</a></span>
                                <div class="progress progress-sm">
                                    <div
                                        className={"progress-bar bg-" + (barWidth < 50 ? "green" : barWidth > 90 ? "red" : "orange")}
                                        style={{width: barWidth + "%"}}></div>
                                </div>
                            </div>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row cards={true}>
                    <Grid.Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>Recently Generated QR Invoices</Card.Title>
                            </Card.Header>
                            <Table
                                cards={true}
                                striped={true}
                                responsive={true}
                                className="table-vcenter"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.ColHeader>Name</Table.ColHeader>
                                        <Table.ColHeader>Creation Date</Table.ColHeader>
                                        <Table.ColHeader>Actions</Table.ColHeader>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.recentlyProcessedInvoices.map((qrInvoice) => (
                                        <Table.Row>
                                            <Table.Col>{qrInvoice.invoice_title}</Table.Col>
                                            <Table.Col>{new Date(qrInvoice.creation_date).toLocaleString()}</Table.Col>
                                            <Table.Col className="w-1">
                                                <div style={{
                                                    textAlign: "center",
                                                    display: (this.state.nowDownloadingQrInvoiceId === qrInvoice.id) ? "block" : "none"
                                                }}><img style={{width: "16px", height: "16px"}} src={spinner}/></div>
                                                <label
                                                    style={{display: (this.state.nowDownloadingQrInvoiceId === qrInvoice.id) ? "none" : "block"}}><Icon
                                                    link={true} name="download"
                                                    onClick={() => this.downloadInvoice(qrInvoice.id, qrInvoice.invoice_title)}></Icon>&nbsp;&nbsp;
                                                    <Icon link={true} name="eye"
                                                          onClick={() => this.viewInvoice(qrInvoice.id, qrInvoice.invoice_title)}></Icon>&nbsp;&nbsp;
                                                    <Icon link={true} name="send"
                                                          onClick={() => this.sendInvoice(qrInvoice.id, qrInvoice.invoice_title)}></Icon>&nbsp;&nbsp;
                                                    <Icon link={true} name="trash"
                                                          onClick={() => this.deleteIconClicked(qrInvoice)}></Icon></label>
                                            </Table.Col>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                            {(this.state.recentlyProcessedInvoices.length === 0) ?
                                <div style={{textAlign: "center", paddingBottom: '20px', paddingTop: '20px'}}>No Recent
                                    QR Invoices. Click "New QR Invoice" and create one.</div> : ""}
                            <div
                                style={{display: (this.state.recentlyProcessedInvoices.length === 0) ? "none" : "block"}}>
                                <Card.Footer>
                                    Showing
                                    page {this.state.currentPage} of {this.state.totalPages} (total {this.state.totalEntries} entries)<br/>
                                    <span style={{display: (this.state.currentPage > 1) ? "inline-block" : "none"}}><a
                                        href="javascript:void(0)"
                                        onClick={() => this.previousPage()}>← Previous Page</a></span>
                                    <span
                                        style={{display: (this.state.currentPage > 1 && this.state.currentPage < this.state.totalPages) ? "inline-block" : "none"}}> &nbsp; | &nbsp; </span>
                                    <a href="javascript:void(0)"
                                       style={{display: (this.state.currentPage < this.state.totalPages) ? "inline-block" : "none"}}
                                       onClick={() => this.nextPage()}>Next Page →</a>
                                </Card.Footer>
                            </div>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        );
    }
}

export default Home;
