export function isValidEmailAddress(str) {
    return !! str.match(/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/);
}

export function popupCenter(url, title, w, h) {
    let left = (window.screen.width/2)-(w/2);
    let top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}