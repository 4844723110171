// @flow

import * as React from "react";
import {NavLink, withRouter} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Nav, RouterContextProvider, Site,} from "tabler-react";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import empty_profile from "./images/empty_profile.png"
import logo from "./images/logo.png"
import qb_connect_button_med from "./images/qb_connect_button_med.svg"

const swalInstance = withReactContent(Swal)

type Props = {|
  +children: React.Node,
|};

type subNavItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +LinkComponent?: React.ElementType,
  +useExact?: boolean,
|};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +active?: boolean,
  +LinkComponent?: React.ElementType,
  +subItems?: Array<subNavItem>,
  +useExact?: boolean,
|};

const navBarItems: Array<navItem> = [
  {
    value: "Dashboard",
    to: "/dashboard",
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: "New QR Invoice",
    icon: "file-text",
    to: "/new-qr",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
      value: "Subscriptions",
      icon: "dollar-sign",
      to: "/subscriptions",
      LinkComponent: withRouter(NavLink),
      useExact: true,
  },
  {
      value: "Settings",
      icon: "settings",
      to: "/settings",
      LinkComponent: withRouter(NavLink),
      useExact: true,
  },
];

class SiteWrapper extends React.Component<Props, State> {
    state = {
        userSettings: null,
        quickBooksConnectionStatus: true,
        currentYear: new Date().getFullYear()
    };

    componentDidMount() {
        Promise.all([
            axios.get(window.SERVER_URL + '/get_settings'),
        ])
            .then(([res1, res2, res3]) => Promise.all([res1.data]))
            .then(([data1]) => {
                    let qbConnectionStatus = data1.result.qb_connection_status;
                    if (qbConnectionStatus === false) {
                        swalInstance.fire({
                            icon: "warning",
                            title: "QuickBooks Is Not Connected",
                            html: <div>QRify requires an active connection to QuickBooks in order to function properly.<br /><br /><a href="/oauth/init"><img src={qb_connect_button_med} /></a></div>,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        })
                    }
                    this.setState({
                        userSettings: data1.result,
                        quickBooksConnectionStatus: qbConnectionStatus
                    });
                }
            );
    }

  render(): React.Node {
        let navItems = (
        <Nav.Item type="div" className="d-none d-md-flex">
            <Button
                href="javascript:$crisp.push(['do', 'chat:open']);"
                outline
                size="sm"
                RootComponent="a"
                color="success"
            >
                Contact Support
            </Button>

        </Nav.Item>
        //<!--Language dropdown -->
        /*
        &nbsp;
        <Button
            href=""
            target="_blank"
            outline
            size="sm"
            RootComponent="a"
            color="primary"
        >
            Help Center
        </Button>
        */
    );
    return (
      <Site.Wrapper
        headerProps={{
          href: "/dashboard",
          alt: "QRify: Add QR Code to Invoices",
          imageURL: logo,
          navItems: navItems,
          accountDropdown: {
              avatarURL: empty_profile,
              name: this.state.userSettings !== null ? this.state.userSettings.first_name + ' ' + this.state.userSettings.last_name : "",
              description: this.state.userSettings !== null ? this.state.userSettings.company_name : "",
              options: [
                  {
                      value: "Your Subscription",
                      icon: "dollar-sign",
                      to: "#",
                      onClick: () => this.props.history.push('/subscriptions'),
                      LinkComponent: withRouter(NavLink),
                      useExact: true,
                  },
                  {
                      value: "Settings",
                      icon: "settings",
                      to: "#",
                      onClick: () => this.props.history.push('/settings'),
                      LinkComponent: withRouter(NavLink),
                      useExact: true,
                  },
                  { isDivider: true },
                  {
                      value: "Sign out",
                      icon: "log-out",
                      to: window.SERVER_URL  + "/sign-out",
                      LinkComponent: withRouter(NavLink),
                      useExact: true,
                  }
              ],
          },
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          note:
            "",
          copyright: (
            <React.Fragment>
              Copyright © {this.state.currentYear}&nbsp;
              <a>QRify</a> by <a
                href="https://effectify.co"
                target="_blank"
            >Effective Apps</a>.&nbsp;Intuit and QuickBooks are registered trademarks of Intuit Inc. Used with permission.
            </React.Fragment>
          ),
        }}
      >
        <ToastContainer position="bottom-center" />
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

export default withRouter(SiteWrapper);
