// @flow

import * as React from "react";
import axios from 'axios';
import {Grid, Card, Button, PricingCard, Page, Icon} from "tabler-react";
import {withRouter} from "react-router-dom";
import spinner from "../images/spinner.gif"
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import {ButtonGroup} from "react-bootstrap";
import arrow from "../images/arrow.png";

class Subscriptions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userSettings: {},
            allPlans: [],
            nowUpgradingPlanId: "",
            upgradeInterval: null,
            planReactivationButtonLoading: false,
            subscriptionReactivationInterval: null,
            subscriptionsBillingType: 2,
        };
    }

    componentDidMount() {
        Promise.all([
            axios.get(window.SERVER_URL + '/get_settings'),
            axios.get(window.SERVER_URL + '/get_all_plans?billing_type=2')
        ])
            .then(([res1, res2]) => Promise.all([res1.data, res2.data]))
            .then(([data1, data2]) => {
                    this.setState({
                        userSettings: data1.result,
                        allPlans: data2.result,
                    });
                }
            );

        this.successCallback = this.successCallback.bind(this);
        this.closeCallback = this.closeCallback.bind(this);
        this.initPaddleSubscriptionUpdate = this.initPaddleSubscriptionUpdate.bind(this);
        this.subscriptionReactivationClose = this.subscriptionReactivationClose.bind(this);
        this.subscriptionReactivationSuccess = this.subscriptionReactivationSuccess.bind(this);
        this.toggleSubscriptionsBillingType = this.toggleSubscriptionsBillingType.bind(this);
    }

    successCallback(data) {
        let newPlanId = data.product.id;
        this.setState({nowUpgradingPlanId: newPlanId})
        this.state.upgradeInterval = setInterval((handler) => {
            axios.get(window.SERVER_URL + '/check_upgrade_status?newPlanId=' + newPlanId).then((res) => {
                if (res.data.upgrade_status === true) {
                    clearInterval(this.state.upgradeInterval);
                    this.props.history.push("/dashboard?showMessage=1");
                }
            });
        }, 1000);
    }

    toggleSubscriptionsBillingType(newValue) {
        this.setState({subscriptionsBillingType: newValue});
        axios.get(window.SERVER_URL + '/get_all_plans?billing_type=' + newValue).then((response) => {
            if (response.status === 200) {
                this.setState({allPlans: response.data.result});
            }
        });
    }

    closeCallback() {
        this.setState({nowUpgradingPlanId: ""});
    }

    subscriptionReactivationClose() {
        this.setState({planReactivationButtonLoading: false});
    }

    subscriptionReactivationSuccess() {
        this.state.subscriptionReactivationInterval = setInterval((handler) => {
            axios.get(window.SERVER_URL + '/check_subscription_status')
            .then((res) => {
                if (res.data.is_active === true) {
                    clearInterval(this.state.subscriptionReactivationInterval);
                    this.props.history.push("/dashboard?showMessage=2");
                }
            });
        }, 1000);
    }

    changePlan(newPlanId) {
        if (newPlanId === 1) {
            axios.post(window.SERVER_URL + '/activate_user_free_plan').then((response) => {
                if (response.status === 200) {
                    this.props.history.push("/dashboard?showMessage=1");
                }
            });
            return;
        }

        let passthroughData = {
            "user_id": this.state.userSettings.id,
            "user_ip_address": window.USER_IP_ADDRESS,
            "client_side_passthrough_date": new Date(),
            "user_user_agent": navigator.userAgent,
        }

        window.Paddle.Checkout.open({
            product: newPlanId,
            email: this.state.userSettings.email_address,
            user_id: this.state.userSettings.id,
            passthrough: JSON.stringify(passthroughData),
            successCallback: (data) => this.successCallback(data),
            closeCallback: () => this.closeCallback()
        });
    }

    initPaddleSubscriptionUpdate() {
        this.setState({planReactivationButtonLoading: true});
        window.Paddle.Checkout.open({
            override: this.state.userSettings.update_url,
            closeCallback: () => this.subscriptionReactivationClose(),
            successCallback: () => this.subscriptionReactivationSuccess(),
        });
    }

    render() {
        return (

            <Page.Content title="Subscriptions">
                {this.state.userSettings.is_active === false ? <Grid.Row cards={true}>
                    <Grid.Col width={12} sm={12} lg={12}>
                        <Card
                            className="subscription-reactivation-banner"
                            statusColor="red"
                            title={<b>Your subscription has been frozen</b>}
                            body={<div>Your QRify subscription has been frozen due to a payment method failure. To reactivate your subscription, please click the button below to update your payment information now:
                                <div className={this.state.planReactivationButtonLoading ? "btn-loading subscription-reactivation-button" : "subscription-reactivation-button"}>
                                    <PricingCard.Button onClick={this.initPaddleSubscriptionUpdate}
                                                        active="success"
                                                        className={this.state.planReactivationButtonLoading ? "pointer-events-none" : ""}>{this.state.planReactivationButtonLoading ?
                                        <span>&nbsp;</span> : "Reactivate My Subscription"}</PricingCard.Button></div>

                                </div>}
                        />
                    </Grid.Col>
                </Grid.Row> : "" }
                <Grid.Row className="billing-type-toggle">
                    <Grid.Col>
                        <ButtonGroup>
                            <Button color={this.state.subscriptionsBillingType === 1 ? "primary" : ""} outline={this.state.subscriptionsBillingType !== 1} onClick={() => this.toggleSubscriptionsBillingType(1)}>
                                Monthly
                            </Button>
                            <Button color={this.state.subscriptionsBillingType === 2 ? "primary" : ""} outline={this.state.subscriptionsBillingType !== 2} onClick={() => this.toggleSubscriptionsBillingType(2)}>
                                Yearly
                            </Button>
                        </ButtonGroup>
                        <label className="billing-type-yearly-arrow"> <img src={arrow} /></label>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    {this.state.allPlans.map((plan) => (
                        <Grid.Col sm={6} lg={3}>
                            <PricingCard>
                                <PricingCard.Category>{plan.plan_name}</PricingCard.Category>
                                <PricingCard.Price>${plan.plan_price}<span
                                    style={{fontSize: "14px"}}>/{plan.billing_type === 1 ? "month" : "year"}</span></PricingCard.Price>
                                <PricingCard.AttributeList>
                                    <PricingCard.AttributeItem>
                                        Generate up to <b style={{fontSize: "15.5px"}}>{plan.max_qr_invoices} QR
                                        Invoices</b> every month
                                    </PricingCard.AttributeItem>
                                </PricingCard.AttributeList>
                                <div style={{textAlign: "left"}}>
                                    <PricingCard.AttributeList>
                                        <PricingCard.AttributeItem hasIcon available>
                                            Full QuickBooks Integration
                                        </PricingCard.AttributeItem>
                                        <PricingCard.AttributeItem hasIcon available>
                                            Add QR Codes to Invoices
                                        </PricingCard.AttributeItem>
                                        <PricingCard.AttributeItem hasIcon available>
                                            Scan2Pay QR Links (like PayPal)
                                        </PricingCard.AttributeItem>
                                        <PricingCard.AttributeItem hasIcon available>
                                            FATOORA QR Codes
                                        </PricingCard.AttributeItem>
                                        <PricingCard.AttributeItem hasIcon available>
                                            Online Support
                                        </PricingCard.AttributeItem>
                                    </PricingCard.AttributeList>
                                </div>
                                <div class={this.state.nowUpgradingPlanId === plan.id ? "btn-loading" : ""}>
                                    <PricingCard.Button onClick={() => this.changePlan(plan.id)}
                                                        active={this.state.userSettings.plan_id === plan.id ? "" : "success"}
                                                        className={this.state.userSettings.plan_id === plan.id ? "pointer-events-none" : ""}>{this.state.userSettings.plan_id === plan.id ? "Your Current Plan" : this.state.nowUpgradingPlanId === plan.id ?
                                        <span>&nbsp;</span> : "Choose This Plan"}</PricingCard.Button></div>
                            </PricingCard>
                        </Grid.Col>
                    ))}
                </Grid.Row>
            </Page.Content>
        );
    }
}

export default withRouter(Subscriptions);