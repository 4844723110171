// @flow

import * as React from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Button, Card, Form, Grid, Icon, Page, Table,} from "tabler-react";
import axios from "axios";
import {Button as ModalButton, Modal} from "react-bootstrap";
import AsyncSelect from "react-select";
import {Document, Page as PDFPage, pdfjs} from 'react-pdf';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {withRouter} from "react-router-dom";
import Slider from 'react-input-slider';
import {isValidEmailAddress} from "../Utils";
import {toast} from "react-toastify";
import qr_code from "../images/qr_code.jpeg"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const swalInstance = withReactContent(Swal);

class NewQR extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userSettings: {},
            pdfPreviewUrl: '',
            pdfFile: null,
            qrPosition: "1",
            goButtonLoading: false,
            goButtonText: "",
            qrData: "",
            qrDataIsInvalid: false,
            qrDataErrorMessage: "",
            qrPaymentLink: '',
            qrPaymentLinkErrorMessage: "",
            qrPaymentLinkIsInvalid: false,
            qrUrl: '',
            qrUrlErrorMessage: "",
            qrUrlIsInvalid: false,
            pdfFileErrorMessage: "",
            qrPage: "1",
            feedbackModalVisibility: false,
            selectedQuickBooksInvoiceId: null,
            quickBooksInvoices: [],
            qrPreviewVisibility: "none",
            resultPdfUrl: '',
            isLoading: false,
            resultQrInvoiceId: null,
            statusMessage: 'Start typing an Invoice number, example: 1001',
            downloadGeneratedQrButtonLoading: false,
            qrType: localStorage.getItem("qrDefaultType") !== null ? localStorage.getItem("qrDefaultType") : "1",
            qrCompanyName: '',
            qrCompanyNameErrorMessage: "",
            qrCompanyNameIsInvalid: false,
            qrTaxNumber: '',
            qrTaxNumberErrorMessage: "",
            qrTaxNumberIsInvalid: false,
            qrInvoiceDT: '',
            qrInvoiceDTErrorMessage: "",
            qrInvoiceDTIsInvalid: false,
            qrTaxAmount: '',
            qrTaxAmountErrorMessage: "",
            qrTaxAmountIsInvalid: false,
            qrTotalAmount: '',
            qrTotalAmountErrorMessage: "",
            qrTotalAmountIsInvalid: false,
            userCurrency: "",
            customPositionX: 0,
            customPositionY: 0,
            showCustomPositions: false,
            customXMessage: "left",
            messageRecipient: '',
            messageRecipientIsInvalid: false,
            messageRecipientErrorMessage: '',
            messageSubject: '',
            messageSubjectIsInvalid: false,
            messageSubjectErrorMessage: '',
            messageContent: '',
            messageContentIsInvalid: false,
            messageContentErrorMessage: '',
            sendInvoiceButtonLoading: false,
        };

        this.changeHandler = this.changeHandler.bind(this);
        this.processPDF = this.processPDF.bind(this);
        this.changeQRPosition = this.changeQRPosition.bind(this);
        this.changeQRType = this.changeQRType.bind(this);
        this.changeQRPage = this.changeQRPage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSliderXChange = this.handleSliderXChange.bind(this);
        this.handleSliderYChange = this.handleSliderYChange.bind(this);
        this.handleSelectedInvoiceChanged = this.handleSelectedInvoiceChanged.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.customPositionsCheckboxChanged = this.customPositionsCheckboxChanged.bind(this);
        this.triggerLimitReachedError = this.triggerLimitReachedError.bind(this);
        this.closeSuccessModal = this.closeSuccessModal.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    changeHandler(event) {
        this.setState({pdfFile: event.target.files[0]});
    }

    componentDidMount() {
        Promise.all([
            axios.get(window.SERVER_URL + '/get_settings'),
        ])
            .then(([res1, res2, res3]) => Promise.all([res1.data]))
            .then(([data1]) => {
                    if (data1.result.is_active === false) {
                        this.props.history.push("/subscriptions");
                    }


                    this.setState({
                        userSettings: data1.result,
                        qrCompanyName: data1.result.company_name,
                        qrTaxNumber: data1.result.tax_number,
                        userCurrency: data1.result.currency_type,
                        customPositionX: data1.result.custom_position_x,
                        customPositionY: data1.result.custom_position_y,
                        showCustomPositions: (data1.result.custom_position_y > 0 || data1.result.custom_position_x > 0)
                    }, () => {
                        let remainingQrInvoices = data1.result.remaining_qr_invoices_this_month;
                        if (remainingQrInvoices < 1) {
                            this.triggerLimitReachedError();
                        }
                    });
                }
            );
    }

    triggerLimitReachedError() {
        let _this = this;
        swalInstance.fire({
            icon: "error",
            title: "Plan Limit Reached",
            text: "You have generated all of your " + this.state.userSettings.plan_name + "'s " + this.state.userSettings.plan_max_qr_invoices_per_month + " QR Invoices for this month. Click OK to purchase more QR invoices.",
            confirmButtonText: "OK",
            confirmButtonColor: "green"
        }).then(function (result) {
            _this.props.history.push('/subscriptions');
        });
    }

    changeQRPage(event) {
        this.setState({
            qrPage: event.target.value
        });
    }

    changeQRPosition(event) {
        this.setState({
            qrPosition: event.target.value,
            customXMessage: event.target.value === "1" ? "left" : "right"
        });
    }

    changeQRType(event) {
        this.setState({
            qrType: event.target.value
        });

        localStorage.setItem("qrDefaultType", event.target.value);
    }

    handleSliderYChange(e) {
        let value = e.x;
        this.setState({customPositionY: value});
    }

    handleSliderXChange(e) {
        let value = e.x;
        this.setState({customPositionX: value});
    }

    handleChange(e) {
        let value = e.target.value;
        if ((e.target.name === "messageSubject" || e.target.name === "messageRecipient") && value.length > 200) {
            return;
        }

        this.setState({[e.target.name]: value});
    }

    processPDF() {
        if (this.state.selectedQuickBooksInvoiceId === null) {
            this.setState({pdfFileErrorMessage: "Please choose an invoice"});
            return;
        } else {
            this.setState({pdfFileErrorMessage: ""});
        }

        if (this.state.qrType === "1") {
            if (this.state.qrUrl.length === 0) {
                this.setState({qrUrlIsInvalid: true, qrUrlErrorMessage: "Please add a link (URL)"});
                return;
            } else {
                this.setState({qrUrlIsInvalid: false, qrUrlErrorMessage: ""});
            }
        } else if (this.state.qrType === "2") {
            if (this.state.qrData.length === 0) {
                this.setState({qrDataIsInvalid: true, qrDataErrorMessage: "Please add data"});
                return;
            } else {
                this.setState({qrDataIsInvalid: false, qrDataErrorMessage: ""});
            }
        } else if (this.state.qrType === "4") {
            if (this.state.qrPaymentLink.length === 0) {
                this.setState({qrPaymentLinkIsInvalid: true, qrPaymentLinkErrorMessage: "Please add a payment link"});
                return;
            } else {
                this.setState({qrPaymentLinkIsInvalid: false, qrPaymentLinkErrorMessage: ""});
            }
        } else {
            if (this.state.qrCompanyName.length === 0) {
                this.setState({
                    qrCompanyNameIsInvalid: true,
                    qrCompanyNameErrorMessage: "Please add your company/seller name"
                });
                return;
            } else {
                this.setState({qrCompanyNameIsInvalid: false, qrCompanyNameErrorMessage: ""});
            }

            if (this.state.qrTaxNumber.length === 0) {
                this.setState({qrTaxNumberIsInvalid: true, qrTaxNumberErrorMessage: "Please add your tax number"});
                return;
            } else {
                this.setState({qrTaxNumberIsInvalid: false, qrTaxNumberErrorMessage: ""});
            }

            if (this.state.qrInvoiceDT.length === 0) {
                this.setState({
                    qrInvoiceDTIsInvalid: true,
                    qrInvoiceDTErrorMessage: "Please add a date and time for the invoice"
                });
                return;
            } else {
                this.setState({qrInvoiceDTIsInvalid: false, qrInvoiceDTErrorMessage: ""});
            }

            if (this.state.qrTaxAmount.length === 0) {
                this.setState({qrTaxAmountIsInvalid: true, qrTaxAmountErrorMessage: "Please add a tax amount"});
                return;
            } else {
                this.setState({qrTaxAmountIsInvalid: false, qrTaxAmountErrorMessage: ""});
            }

            if (this.state.qrTotalAmount.length === 0) {
                this.setState({qrTotalAmountIsInvalid: true, qrTotalAmountErrorMessage: "Please add a total amount"});
                return;
            } else {
                this.setState({qrTotalAmountIsInvalid: false, qrTotalAmountErrorMessage: ""});
            }
        }

        this.setState({goButtonLoading: true});
        axios.post(window.SERVER_URL + '/process_pdf', {
            "qb_invoice_id": this.state.selectedQuickBooksInvoiceId.value,
            "qr_position": this.state.qrPosition,
            "qr_page": this.state.qrPage,
            "qr_type": this.state.qrType,
            "qr_data": this.state.qrData,
            "qr_payment_link": this.state.qrPaymentLink,
            "qr_url": this.state.qrUrl,
            "qr_company_name": this.state.qrCompanyName,
            "qr_tax_number": this.state.qrTaxNumber,
            "qr_invoice_datetime": this.state.qrInvoiceDT,
            "qr_tax_amount": this.state.qrTaxAmount,
            "qr_total_amount": this.state.qrTotalAmount,
            "invoice_title": this.state.selectedQuickBooksInvoiceId.label,
            "custom_position_x": this.state.customPositionX,
            "custom_position_y": this.state.customPositionY
        }).then((res) => {
            this.setState({
                goButtonLoading: false,
                feedbackModalVisibility: true,
                resultPdfUrl: res.data.generated_pdf_url,
                resultQrInvoiceId: res.data.generated_invoice_id,
                messageRecipient: this.state.selectedQuickBooksInvoiceId.invoiceEmail,
                messageSubject: this.state.selectedQuickBooksInvoiceId.label.substring(0, this.state.selectedQuickBooksInvoiceId.label.indexOf('(') - 1) + " from " + this.state.userSettings.company_name,
                messageContent: "Hello,\n\nWe appreciate your business. Please find your invoice details here. Feel free to contact us if you have any questions.\n\nHave a great day!\n" + this.state.userSettings.company_name,
            });
            if (localStorage.getItem("firstInvoiceCreated") === null) {
                window.$crisp.push(["set", "session:event", [[["qrify:firstinvoicecreated", {}, "red"]]]]);
                localStorage.setItem("firstInvoiceCreated", "true");
            }
        }).catch((err) => console.log(err));
    }

    sendEmail() {
        let _this = this;
        this.setState({
            sendInvoiceButtonLoading: true,
            messageRecipientIsInvalid: false,
            messageSubjectIsInvalid: false
        });
        if (this.state.messageRecipient.length < 1 || isValidEmailAddress(this.state.messageRecipient) === false) {
            this.setState({messageRecipientIsInvalid: true, sendInvoiceButtonLoading: false});
            return;
        }

        if (this.state.messageSubject.length < 1) {
            this.setState({messageSubjectIsInvalid: true, sendInvoiceButtonLoading: false});
            return;
        }

        axios.post(window.SERVER_URL + '/send_user_qr_invoice_as_email',
            {
                qr_invoice_id: this.state.resultQrInvoiceId,
                message_recipient: this.state.messageRecipient,
                message_subject: this.state.messageSubject,
                message_content: this.state.messageContent,
            }
        ).then(function (response) {
            if (response.status === 200) {
                toast.success("Invoice sent successfully");
                _this.setState({
                    sendInvoiceButtonLoading: false,
                    messageRecipient: '',
                    messageSubject: '',
                    messageContent: '',
                    feedbackModalVisibility: false
                });
            }
        }).catch(error => {
            console.log(error);
            toast.error("An error has occurred");
        });
    }

    handleSelectedInvoiceChanged(e) {
        axios.get(window.SERVER_URL + '/invoice/' + e.value).then((res) => {
            this.setState({qrPaymentLink: res.data.result['payment_link']});
        }).catch((err) => console.log(err));

        this.setState({
            selectedQuickBooksInvoiceId: e,
            pdfPreviewUrl: (e !== null) ? window.SERVER_URL + '/mirror_user_pdf/' + e.value + '.pdf' : '',
            qrTotalAmount: (e !== null) ? e.totalAmount : '',
            qrTaxAmount: (e !== null) ? e.taxAmount : '',
            qrInvoiceDT: (e !== null) ? e.invoiceDate : '',
            pdfFileErrorMessage: ""
        });
    }

    onKeyDown(e) {
        if (e.target.value === "") {
            return;
        }
        e.target.value = e.target.value.replace(/[^0-9]/ig, '')
        let _this = this;
        this.setState({statusMessage: "Searching...", isLoading: true});
        axios.post(window.SERVER_URL + '/search_invoices', {
            text: e.target.value
        }).then(function (response) {
            _this.setState({
                quickBooksInvoices: response.data.quickbooks_invoices,
                isLoading: false,
                statusMessage: 'No Results'
            })
        });
    }

    customPositionsCheckboxChanged(e) {
        this.setState({
            showCustomPositions: e.target.checked,
            customPositionX: e.target.checked === false ? 0 : this.state.customPositionX,
            customPositionY: e.target.checked === false ? 0 : this.state.customPositionY
        });
    }

    downloadGeneratedQrInvoice(resultQrInvoiceId, invoiceTitle) {
        this.setState({downloadGeneratedQrButtonLoading: true});
        axios.get(window.SERVER_URL + '/download_user_qr_pdf/' + resultQrInvoiceId + '.pdf').then((res) => {
            const link = document.createElement('a');
            link.href = res.data;
            link.setAttribute('target', '_blank');
            link.setAttribute('download', invoiceTitle + " (QRify).pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.setState({downloadGeneratedQrButtonLoading: false});
        }).catch((err) => console.log(err));
    }

    closeSuccessModal() {
        this.setState({
            feedbackModalVisibility: false,
            selectedQuickBooksInvoiceId: null,
            qrData: "",
            pdfPreviewUrl: ""
        });
        axios.get(window.SERVER_URL + '/get_settings').then((res) => {
            let remainingQrInvoices = res.data.result.remaining_qr_invoices_this_month;
            if (remainingQrInvoices < 1) {
                this.triggerLimitReachedError();
            }
        })

    }

    render() {
        return (
            <Page.Content title="New QR Invoice">
                <Modal dialogClassName="onboarding-modal" show={this.state.feedbackModalVisibility}
                       onHide={this.closeSuccessModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success! A QR code has been added to the selected invoice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid.Row>
                            <Grid.Col width={6} sm={6} lg={6}>
                                <div>
                                    <h4>Email Generated Invoice: </h4>
                                    <Form.Group>
                                        <b>To:</b><br/>
                                        <Form.Input
                                            name="messageRecipient"
                                            maxLength="200"
                                            invalid={this.state.messageRecipientIsInvalid}
                                            placeholder="Enter message recipient here"
                                            onChange={this.handleChange}
                                            value={this.state.messageRecipient}
                                        />
                                        <span className="invalid-feedback">Invalid recipient address</span>
                                    </Form.Group>
                                    <Form.Group>
                                        <b>Subject:</b><br/>
                                        <Form.Input
                                            name="messageSubject"
                                            maxLength="200"
                                            title="Subject"
                                            invalid={this.state.messageSubjectIsInvalid}
                                            value={this.state.messageSubject}
                                            placeholder="Enter message subject here"
                                            onChange={this.handleChange}
                                        />
                                        <span className="invalid-feedback">The message subject cannot be empty</span>
                                    </Form.Group>
                                    <Form.Group>
                                        <b>Message:</b> (optional)<br/>
                                        <Form.Textarea
                                            maxLength={2000}
                                            name="messageContent"
                                            rows="10"
                                            type="text"
                                            invalid={this.state.messageContentIsInvalid}
                                            placeholder="Enter message body here (optional)"
                                            onChange={this.handleChange}
                                            value={this.state.messageContent}/>
                                        <span
                                            className="invalid-feedback">{this.state.messageContentErrorMessage}</span>
                                        *The Invoice will be sent as an attachment
                                    </Form.Group>
                                </div>
                            </Grid.Col>
                            <Grid.Col width={6} sm={6} lg={6}>
                                <iframe style={{width: "350px", height: "450px", border: "0"}}
                                        src={this.state.resultPdfUrl}>
                                </iframe>
                            </Grid.Col>
                        </Grid.Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => window.$crisp.push(['do', 'chat:open'])} color="secondary">I need
                            help <Icon name="help-circle"/></Button>
                        <Button loading={this.state.downloadGeneratedQrButtonLoading}
                                onClick={() => this.downloadGeneratedQrInvoice(this.state.resultQrInvoiceId, this.state.selectedQuickBooksInvoiceId.label)}
                                color="primary">Download <Icon name="download"/></Button>
                        <Button loading={this.state.sendInvoiceButtonLoading} color="success" onClick={this.sendEmail}>Send
                            Invoice&nbsp;<Icon name="send"></Icon>&nbsp;&nbsp;</Button>
                    </Modal.Footer>
                </Modal>
                <Grid.Row cards={true}>
                    <Grid.Col width={7} sm={7} lg={7}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Add a QR code to an Invoice</Card.Title>
                            </Card.Header>
                            <div className="d-flex" style={{
                                borderBottom: "1px solid rgba(0, 40, 100, 0.12)",
                                paddingTop: "1rem",
                                paddingRight: "1.5rem",
                                paddingBottom: "1rem",
                                paddingLeft: "1.5rem"
                            }}>
                                <Button loading={this.state.goButtonLoading} type="submit" onClick={this.processPDF}
                                        color="primary" className="ml-auto">
                                    Generate QR Invoice
                                </Button>
                            </div>
                            <Card.Body>
                                <label style={{fontSize: "1.125rem"}}><b>Choose an Invoice:</b></label><br/>
                                <AsyncSelect
                                    cacheOptions
                                    options={this.state.quickBooksInvoices}
                                    onChange={this.handleSelectedInvoiceChanged}
                                    onKeyDown={this.onKeyDown}
                                    value={this.state.selectedQuickBooksInvoiceId}
                                    placeholder="Click here and search for an invoice by its number..."
                                    noOptionsMessage={() => this.state.statusMessage}
                                    isClearable={true}
                                    isLoading={this.state.isLoading}
                                />
                                <span className="invalid-feedback"
                                      style={{display: "block"}}>{this.state.pdfFileErrorMessage}</span>
                                <br/>
                                <label style={{fontSize: "1.125rem"}}><b>QR Type:</b></label><br/>
                                <input type="radio" name="qr-type" value="4" checked={this.state.qrType === "4"}
                                       onChange={this.changeQRType}/> Invoice's Payment Link (From QuickBooks)<br/>
                                <div style={{
                                    paddingLeft: "15px",
                                    display: (this.state.qrType === "4") ? "block" : "none"
                                }}>
                                    <Form.Input
                                        name="qrPaymentLink"
                                        maxLength="200"
                                        invalid={this.state.qrPaymentLinkIsInvalid}
                                        placeholder="https://connect.intuit.com/t/..."
                                        onChange={this.handleChange}
                                        value={this.state.qrPaymentLink}
                                    />
                                    <span className="invalid-feedback">{this.state.qrPaymentLinkErrorMessage}</span>
                                </div>
                                <input type="radio" name="qr-type" value="1" checked={this.state.qrType === "1"}
                                       onChange={this.changeQRType}/> Link (URL)<br/>
                                <div style={{
                                    paddingLeft: "15px",
                                    display: (this.state.qrType === "1") ? "block" : "none"
                                }}>
                                    <Form.Input
                                        name="qrUrl"
                                        maxLength="200"
                                        invalid={this.state.qrUrlIsInvalid}
                                        placeholder="https://example.com/?invoice=123456"
                                        onChange={this.handleChange}
                                        value={this.state.qrUrl}/>
                                    <span className="invalid-feedback">{this.state.qrUrlErrorMessage}</span>
                                </div>
                                <input type="radio" name="qr-type" value="2" checked={this.state.qrType === "2"}
                                       onChange={this.changeQRType}/> Data (text)<br/>
                                <div style={{
                                    paddingLeft: "15px",
                                    display: (this.state.qrType === "2") ? "block" : "none"
                                }}>
                                    <Form.Textarea
                                        maxLength={200}
                                        name="qrData"
                                        rows="2"
                                        type="text"
                                        invalid={this.state.qrDataIsInvalid}
                                        placeholder="Enter textual data here..."
                                        onChange={this.handleChange}
                                        value={this.state.qrData}/>
                                    <span className="invalid-feedback">{this.state.qrDataErrorMessage}</span>
                                </div>
                                <input type="radio" name="qr-type" value="3" checked={this.state.qrType === "3"}
                                       onChange={this.changeQRType}/> FATOORA (e-Invoice for KSA)<br/>
                                <div style={{
                                    paddingLeft: "15px",
                                    display: (this.state.qrType === "3") ? "block" : "none"
                                }}>
                                    <Form.Group>
                                        <span style={{color: "gray"}}><i>
                                            ⓘ <b>Important:</b> FATOORA QR Codes should be scanned with the official ZATCA app:<br/>
                                            <a href="https://bit.ly/3ZZzr1J" target="_blank"><b>Download for iOS</b></a><br/>
                                            <a href="https://bit.ly/498jlXN" target="_blank"><b>Download for Android</b></a>
                                        </i></span><br/>
                                        <i>Company/Seller Name:</i>
                                        <Form.Input
                                            name="qrCompanyName"
                                            maxLength="100"
                                            invalid={this.state.qrCompanyNameIsInvalid}
                                            placeholder="Example Company"
                                            onChange={this.handleChange}
                                            value={this.state.qrCompanyName}/>
                                        <span className="invalid-feedback">{this.state.qrCompanyNameErrorMessage}</span>
                                        <i>Tax/VAT Number:</i>
                                        <Form.Input
                                            name="qrTaxNumber"
                                            maxLength="200"
                                            invalid={this.state.qrTaxNumberIsInvalid}
                                            placeholder="1234567891234"
                                            onChange={this.handleChange}
                                            value={this.state.qrTaxNumber}/>
                                        <span className="invalid-feedback">{this.state.qrTaxNumberErrorMessage}</span>
                                        <i>Date and Time:</i>
                                        <Form.MaskedInput
                                            name="qrInvoiceDT"
                                            invalid={this.state.qrInvoiceDTIsInvalid}
                                            placeholder="dd/mm/yyyy HH:MM"
                                            mask={[
                                                /\d/,
                                                /\d/,
                                                "/",
                                                /\d/,
                                                /\d/,
                                                "/",
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                " ",
                                                /\d/,
                                                /\d/,
                                                ":",
                                                /\d/,
                                                /\d/,
                                            ]}
                                            onChange={this.handleChange}
                                            value={this.state.qrInvoiceDT}
                                        />
                                        <span className="invalid-feedback">{this.state.qrInvoiceDTErrorMessage}</span>
                                        <i>Tax Amount:</i>
                                        <Form.InputGroup>
                                            <Form.InputGroupPrepend>
                                                <Form.InputGroupText>{this.state.userCurrency}</Form.InputGroupText>
                                            </Form.InputGroupPrepend>
                                            <Form.Input
                                                name="qrTaxAmount"
                                                maxLength="12"
                                                mask={[
                                                    /\d/,
                                                    /\d/,
                                                    ".",
                                                    /\d/,
                                                    /\d/
                                                ]}
                                                step="0.01"
                                                type="number"
                                                invalid={this.state.qrTaxAmountIsInvalid}
                                                placeholder="2.01"
                                                onChange={this.handleChange}
                                                value={this.state.qrTaxAmount}/>
                                        </Form.InputGroup>
                                        <span className="invalid-feedback">{this.state.qrTaxAmountMessage}</span>
                                        <i>Total Amount:</i>
                                        <Form.InputGroup>
                                            <Form.InputGroupPrepend>
                                                <Form.InputGroupText>{this.state.userCurrency}</Form.InputGroupText>
                                            </Form.InputGroupPrepend>
                                            <Form.Input
                                                name="qrTotalAmount"
                                                maxLength="12"
                                                mask={[
                                                    /\d/,
                                                    /\d/,
                                                    ".",
                                                    /\d/,
                                                    /\d/
                                                ]}
                                                step="0.01"
                                                type="number"
                                                invalid={this.state.qrTotalAmountIsInvalid}
                                                placeholder="13.37"
                                                onChange={this.handleChange}
                                                value={this.state.qrTotalAmount}/>
                                        </Form.InputGroup>
                                        <span className="invalid-feedback">{this.state.qrTotalAmountErrorMessage}</span>
                                    </Form.Group>
                                </div>
                                <br/>
                                <label style={{fontSize: "1.125rem"}}><b>QR Position:</b></label><br/>
                                <Form.Group>
                                    <input type="radio" name="qr-position" value="1"
                                           checked={this.state.qrPosition === "1"}
                                           onChange={this.changeQRPosition}/> Top Right <br/>
                                    <input type="radio" name="qr-position" value="2"
                                           checked={this.state.qrPosition === "2"}
                                           onChange={this.changeQRPosition}/> Top Left <br/>
                                </Form.Group>
                                <Form.Group>
                                    <span style={{
                                        display: (this.state.userSettings.plan_id > 1) ? "none" : "block",
                                        fontWeight: "bold"
                                    }}><span style={{color: "#aa4dc8"}}>ⓘ Paid plan required</span> - <a
                                        style={{color: "blue"}} href="javascript:void(0);"
                                        onClick={() => this.props.history.push(('/subscriptions'))}>Upgrade Plan</a><br/></span>
                                    <span style={{
                                        opacity: (this.state.userSettings.plan_id > 1) ? "1" : "0.5",
                                        pointerEvents: (this.state.userSettings.plan_id > 1) ? "auto" : "none"
                                    }}>
                                    <input type="checkbox" checked={this.state.showCustomPositions}
                                           onChange={this.customPositionsCheckboxChanged}/> Move
                                    the position of the QR code (if your invoice has a logo)<br/></span>
                                    <div style={{display: this.state.showCustomPositions === true ? "block" : "none"}}>
                                        <span style={{color: "gray"}}><i>ⓘ This option allows you to move the position of the QR page on the generate QR Invoice</i></span><br/>
                                        Custom Horizontal Position (X): <br/>
                                        <span
                                            style={{color: "gray"}}><i>Makes the QR code move to the <b>{this.state.customXMessage}</b> side of the Invoice</i></span><br/>
                                        <Slider
                                            axis="x"
                                            xmax="700"
                                            x={this.state.customPositionX}
                                            onChange={this.handleSliderXChange}
                                        />&nbsp;&nbsp;&nbsp;{this.state.customPositionX}
                                        <br/>
                                        Custom Vertical Position (Y): <br/>
                                        <span style={{color: "gray"}}><i>Makes the QR code appear <b>lower</b> in the Invoice</i></span><br/>
                                        <Slider
                                            axis="x"
                                            xmax="700"
                                            x={this.state.customPositionY}
                                            onChange={this.handleSliderYChange}
                                        />&nbsp;&nbsp;&nbsp;{this.state.customPositionY}
                                    </div>
                                </Form.Group>
                                <label style={{fontSize: "1.125rem"}}><b>Add QR To:</b></label><br/>
                                <Form.Group>
                                    <input type="radio" name="qr-page" value="1" checked={this.state.qrPage === "1"}
                                           onChange={this.changeQRPage}/> Just The First Page <br/>
                                    <input type="radio" name="qr-page" value="2" checked={this.state.qrPage === "2"}
                                           onChange={this.changeQRPage}/> All Pages
                                </Form.Group><br/>
                            </Card.Body>
                            <Card.Footer>
                                <div className="d-flex">
                                    <Button loading={this.state.goButtonLoading} type="submit" onClick={this.processPDF}
                                            color="primary" className="ml-auto">
                                        Generate QR Invoice
                                    </Button>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Grid.Col>
                    <Grid.Col width={5} sm={5} lg={5}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Result Preview (First Page)</Card.Title>
                            </Card.Header>
                            <img className="qr-preview-right"
                                 style={{
                                     display: (this.state.qrPosition === "1" && this.state.selectedQuickBooksInvoiceId !== null) ? this.state.qrPreviewVisibility : "none",
                                     width: "60px",
                                     right: (this.state.customPositionX > 0) ? this.state.customPositionX - 20 + "px" : "11px",
                                     top: this.state.customPositionY + "px",
                                     marginTop: "65px",
                                     zIndex: 1,
                                     position: "absolute"
                                 }} src={qr_code}/>
                            <img className="qr-preview-left"
                                 style={{
                                     display: (this.state.qrPosition === "2" && this.state.selectedQuickBooksInvoiceId !== null) ? this.state.qrPreviewVisibility : "none",
                                     width: "60px",
                                     left: (this.state.customPositionX > 70) ? this.state.customPositionX - 50 + "px" : "11px",
                                     top: this.state.customPositionY + "px",
                                     marginTop: "65px",
                                     zIndex: 1,
                                     position: "absolute"
                                 }} src={qr_code}/>
                            <Document file={this.state.pdfPreviewUrl}
                                      onLoadSuccess={() => this.setState({qrPreviewVisibility: (this.state.selectedQuickBooksInvoiceId !== null) ? "block" : "none"})}>
                                <PDFPage pageNumber={1}/>
                            </Document>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        );
    }
}

export default withRouter(NewQR);
