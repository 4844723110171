// @flow

import * as React from "react";
import axios from 'axios';
import { isValidEmailAddress } from "../Utils"
import qb_connect_button from "../images/qb_connect_button.svg"
import {Button, Card, Form, Grid, Icon, Page, Table, StatsCard, ProgressCard} from "tabler-react";
import {toast} from 'react-toastify';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const swalInstance = withReactContent(Swal);

class Settings extends React.Component {

  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      emailAddress: '',
      quickBooksConnectionStatus: true,
      isFirstNameInvalid: false,
      firstNameErrorMessage: '',
      isLastNameInvalid: false,
      lastNameErrorMessage: '',
      isEmailAddressInvalid: false,
      emailAddressErrorMessage: '',
      isCompanyNameInvalid: false,
      companyNameErrorMessage: '',
      isTaxNumberInvalid: false,
      taxNumberErrorMessage: '',
      currencyType: "",
      isCurrencyTypeInvalid: false,
      currencyTypeErrorMessage: '',
      planName: '',
      taxNumber: '',
      companyName: ''
    };

    this.saveSettings = this.saveSettings.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    Promise.all([
      axios.get(window.SERVER_URL + '/get_settings')
    ])
        .then(([res]) => Promise.all([res.data]))
        .then(([data]) => {
              if (data.result.is_active === false) {
                  this.props.history.push("/subscriptions");
              }

              this.setState({
                firstName: data.result.first_name,
                lastName: data.result.last_name,
                emailAddress: data.result.email_address,
                planName: data.result.plan_name,
                taxNumber: data.result.tax_number,
                companyName: data.result.company_name,
                currencyType: data.result.currency_type
              });

            }
        );
  }

  handleChange(evt) {
    let value = evt.target.value;
    if (evt.target.name === "emailAddress") {
      value = value.replace(/[^A-Za-z0-9\@\-\.]/ig, '')
    }

    this.setState({
      [evt.target.name]: value
    });
  }

  reConnectWithQuickBooks() {

  }

  disconnectFromQuickBooks() {
    swalInstance.fire({
      icon: "warning",
      title: "Are you sure you'd like to disconnect the QuickBooks connection?",
      text: "The app will stop working until you'll connect again",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes, Disconnect QuickBooks Connection",
      confirmButtonColor: "red",
      showCancelButton: true
    }).then(function(result) {
      if (result.isConfirmed) {
        window.location.href = window.SERVER_URL + '/disconnect_quickbooks';
      }
    });
  }

  saveSettings() {
    if (this.state.firstName.length === 0) {
      this.setState({
        isFirstNameInvalid: true,
        firstNameErrorMessage: <div>This field cannot be empty</div>
      });
      return;
    }

    if (this.state.lastName.length === 0) {
      this.setState({
        isLastNameInvalid: true,
        lastNameErrorMessage: <div>This field cannot be empty</div>
      });
      return;
    }

    if (this.state.companyName.length === 0) {
      this.setState({
        isCompanyNameInvalid: true,
        companyNameErrorMessage: <div>This field cannot be empty</div>
      });
      return;
    }

    if (this.state.taxNumber.length === 0) {
      this.setState({
        isTaxNumberInvalid: true,
        taxNumberErrorMessage: <div>This field cannot be empty</div>
      });
      return;
    }

    if (this.state.currencyType.length === 0) {
      this.setState({
        isCurrencyTypeInvalid: true,
        currencyTypeErrorMessage: <div>This field cannot be empty</div>
      });
      return;
    }

    if (this.state.emailAddress.length === 0) {
      this.setState({
        isEmailAddressInvalid: true,
        emailAddressErrorMessage: <div>This field cannot be empty</div>
      });
      return;
    }

    if (isValidEmailAddress(this.state.emailAddress) === false) {
      this.setState({
        isEmailAddressInvalid: true,
        emailAddressErrorMessage: <div>Invalid email address</div>
      });
      return;
    }

    this.setState({
      isFirstNameInvalid: false,
      firstNameErrorMessage: '',
      isLastNameInvalid: false,
      lastNameErrorMessage: '',
      isEmailAddressInvalid: false,
      emailAddressErrorMessage: ''
    });

    axios.post(window.SERVER_URL + '/update_user_settings', {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      company_name: this.state.companyName,
      tax_number: this.state.taxNumber,
      currency_type: this.state.currencyType,
    }).then(function (response) {
      if (response.status === 200) {
        toast.success("Settings saved successfully");
      }
    }).catch(error => {
      toast.error("An error has occurred");
    });

    this.setState({
      isInvalid: false,
      errorMessage: ''
    });
  }

  render() {
    return (

          <Page.Content title="Settings">
              <Grid.Row cards={true}>
                <Grid.Col width={6} sm={6} lg={6}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Settings & Personal Details</Card.Title>
                    </Card.Header>
                      <Card.Body>
                          <Form.Group label="Company/Seller Name">
                              <Form.Input
                                  name="companyName"
                                  maxLength={100}
                                  invalid={this.state.isCompanyNameInvalid}
                                  onChange={this.handleChange}
                                  type="text"
                                  value={this.state.companyName}
                                  placeholder="Enter Company/Seller Name..."
                              />
                              <span className="invalid-feedback">{this.state.companyNameErrorMessage}</span>
                          </Form.Group>
                          <Form.Group label="Tax Number">
                              <Form.Input
                                  name="taxNumber"
                                  invalid={this.state.isTaxNumberInvalid}
                                  maxLength={100}
                                  onChange={this.handleChange}
                                  type="text"
                                  value={this.state.taxNumber}
                                  placeholder="Enter Tax Number..."
                              />
                              <span className="invalid-feedback">{this.state.taxNumberErrorMessage}</span>
                          </Form.Group>
                        <Form.Group label="Currency Type">
                          <Form.Input
                              name="currencyType"
                              maxLength={10}
                              invalid={this.state.isCurrencyTypeInvalid}
                              onChange={this.handleChange}
                              type="text"
                              value={this.state.currencyType}
                              placeholder="$"
                          />
                          <span className="invalid-feedback">{this.state.currencyTypeErrorMessage}</span>
                        </Form.Group>
                      </Card.Body>
                      <Card.Body>
                      <Form.Group label="First Name">
                        <Form.Input
                            name="firstName"
                            maxLength={20}
                            invalid={this.state.isFirstNameInvalid}
                            onChange={this.handleChange}
                            type="text"
                            value={this.state.firstName}
                            placeholder="Enter First Name..."
                        />
                        <span className="invalid-feedback">{this.state.firstNameErrorMessage}</span>
                      </Form.Group>
                      <Form.Group label="Last Name">
                        <Form.Input
                            name="lastName"
                            maxLength={20}
                            invalid={this.state.isLastNameInvalid}
                            onChange={this.handleChange}
                            type="text"
                            value={this.state.lastName}
                            placeholder="Enter Last Name..."
                        />
                        <span className="invalid-feedback">{this.state.lastNameErrorMessage}</span>
                      </Form.Group>
                      <Form.Group label="Email Address">
                        <Form.Input
                            name="emailAddress"
                            disabled
                            maxLength={255}
                            invalid={this.state.isEmailAddressInvalid}
                            onChange={this.handleChange}
                            type="email"
                            value={this.state.emailAddress}
                            placeholder="Enter Email Address..."
                        />
                        <span className="invalid-feedback">{this.state.emailAddressErrorMessage}</span>
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                      <div className="d-flex">

                        <Button onClick={this.saveSettings} type="submit" color="primary" className="ml-auto">
                          Save
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                </Grid.Col>
                <Grid.Col width={6} sm={6} lg={6}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Plan & QuickBooks Connection</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form.Group label="QuickBooks Connection Status">
                        <Button
                            href="javascript:void(0);"
                            className="pointer-events-none"
                            size="sm"
                            RootComponent="a"
                            color={this.state.quickBooksConnectionStatus ? "success" : "danger"}
                        >
                          {this.state.quickBooksConnectionStatus ? "Connected To QuickBooks" : "Not Connected To QuickBooks"}
                        </Button>
                        &nbsp;
                        {this.state.quickBooksConnectionStatus ? <span>&nbsp;<a style={{fontSize: "12px"}} href="javascript:void(0)" onClick={this.disconnectFromQuickBooks}>Disconnect from QuickBooks</a></span> : <span>&nbsp;<a href="javascript:void(0)" onClick={this.reConnectWithQuickBooks}><img src={qb_connect_button} /></a></span>}
                      </Form.Group>
                      <Form.Group label="Subscription Plan">
                        <Button
                            href="javascript:void(0);"
                            className="pointer-events-none"
                            size="sm"
                            RootComponent="a"
                            color="success"
                        >
                          {this.state.planName}
                        </Button>
                        &nbsp;
                        {this.state.quickBooksConnectionStatus ? <span>&nbsp;<a style={{fontSize: "12px"}} href="javascript:void(0)" onClick={() => this.props.history.push('/subscriptions')}>Change Plan</a></span> : <span>&nbsp;<a href="javascript:void(0)" onClick={this.reConnectWithQuickBooks}><img src={qb_connect_button} /></a></span>}
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Grid.Col>
              </Grid.Row>
            </Page.Content>
    );
  }
}

export default Settings;