// @flow

import * as React from "react";
import qb_sign_in_button_med from "../images/qb_sign_in_button_med.svg"
import logo from "../images/logo.png"

class Login extends React.Component {

  render() {
    return (
        <div className="row">
          <div className="col col-login mx-auto">
            <br />
            <div className="text-center mb-6"><img src={logo} className="h-6" alt="logo" /></div>
            <form className="card" autoComplete="off">
              <div className="card-body p-6">
                <div className="card-title">Use your QuickBooks account in order to sign in:</div>
                <div className="form-footer" style={{textAlign: "center"}}>
                  <a href={window.SERVER_URL + '/sso/login'}><img src={qb_sign_in_button_med} /></a>
                </div>
              </div>
            </form>
          </div>
        </div>
    );
  }
}

export default Login;
