import * as React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import HomePage from "./components/HomePage.react";
import Settings from "./components/Settings.react";
import NewQR from "./components/NewQR.react";
import "tabler-react/dist/Tabler.css";
import Login from "./components/LoginPage.react";
import SiteWrapper from "./SiteWrapper.react";
import Subscriptions from "./components/Subscriptions.react";

type Props = {||};

function App(props: Props): React.Node {
  return (
    <React.StrictMode>
        <Router>
            <Switch>
                <Route exact path="/login" component={Login} />
                <SiteWrapper>
                    <Route exact path="/settings" component={Settings} />
                    <Route exact path="/dashboard" component={HomePage} />
                    <Route exact path="/new-qr" component={NewQR} />
                    <Route exact path="/subscriptions" component={Subscriptions} />
                    <Redirect from='/' to='/dashboard' />
                </SiteWrapper>
                <Redirect from='*' to='/dashboard' />
            </Switch>
      </Router>
    </React.StrictMode>
  );
}

export default App;
